
function showTabs(tabsNav, tabsContent) {
  tabsNav?.forEach((navLink, index) => {
    navLink?.addEventListener('click', () => {
      console.log('CLICK')
      tabsNav.forEach(link => {
        link.classList.remove('active');
      });

      navLink.classList.add('active');

      let navIndex = index;

      tabsContent.forEach((item, index) => {
        item.classList.remove('active');

        if (index === navIndex) {
          item.classList.add('active');
        }
      });
    });
  });

}

const catalogTabsNav = document.querySelectorAll('.catalog .tabs__link');
const catalogTabsContent = document.querySelectorAll('.catalog .tabs__content');
showTabs(catalogTabsNav, catalogTabsContent);